export const ORDER_NUMBER_PARAM_NAME = 'orderNumber';

export const ORDER_USER_TYPES = {
  NO_PROFILE_FLOW: 'NO_PROFILE_FLOW',
  PARTNER_API_CLIENT: 'PARTNER_API_CLIENT',
  DASHBOARD: 'DASHBOARD',
};

export const ORDER_USER_TYPES_TITLE = {
  [ORDER_USER_TYPES.NO_PROFILE_FLOW]: 'NO PROFILE FLOW',
  [ORDER_USER_TYPES.PARTNER_API_CLIENT]: 'PARTNER API CLIENT',
  [ORDER_USER_TYPES.DASHBOARD]: 'DASHBOARD',
};
