export const PLUG = 'plug';
export const BACKSPACE = 'backspace';

export const NUMERIC_KEYBOARD_LIST: string[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  PLUG,
  '0',
  BACKSPACE,
];
